var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dv-border-box-12",
    { staticClass: "border-static", attrs: { color: ["#003b51", "#109eb5"] } },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }